<template>
  <div>
    <div class="mt-3">
      <vs-row vs-w="12" vs-justify="flex-start" vs-align="flex-start">
        <vs-col vs-w="3.2" vs-justify="flex-start" vs-align="flex-start">
          <div class="w-full">
            <a target="_blank" href="/agenda-lembrete">
              <vs-chip color="success">
                <vs-avatar icon="today" color="dark" />
                <span style="color: black">{{ qtd ? qtd : 0 }} Lembretes</span>
              </vs-chip>
            </a>
          </div>
        </vs-col>
      </vs-row>
    </div>
  </div>
</template>
<script>
import components from "@/components/default/exports.js";
export default {
  components: {
    ...components,
  },
  data() {
    return {
      qtd: 0,
      filtro: {
        data: this.$formartData.dataFiltro(),
        id_colaborador: null,
      },
    };
  },
  methods: {
    async getQtdLembretes() {
      try {
        this.filtro.id_colaborador = window.localStorage.getItem("id");
        const res = await this.$http.post("getLembretes", this.filtro);
        this.qtd = res.length;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
  },
  async mounted() {
    await this.getQtdLembretes();
  },
};
</script>
