<template>
  <div class="containerItens">
    <vs-row
      class="mb-10"
      vs-justify="flex-start"
      vs-align="flex-start"
      vs-type="flex"
    >    
      <vs-col vs-w="12" vs-type vs-justify="center" class vs-align="center">
        <h4>{{ nome }}!</h4>
        <lembreteModal></lembreteModal>
      </vs-col>

      <!--Inicio Releases-->
      <vs-col vs-w="12" vs-type vs-justify="center" class vs-align="center">
        <h5 class="mt-10 mb-4">RELEASES</h5>  
        <div>
          <vs-row class="mt-3" vs-justify="flex-start" vs-align="flex-start">
            <vs-col vs-w="1.3" class="mx-2">
              <vx-card class="cardAdmin overflow-hidden">
                <template slot="no-body">
                  <div class="item-details" @click="DRE()">
                    <div class="my-1">
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span>
                          <vs-icon
                            icon="new_releases"
                            class="my-1 img"
                            size="40px"
                            color="#083357"
                          ></vs-icon>
                        </span>
                      </vs-col>
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span style="font-size: 14px" class="mb-2"
                          >Versão 4.0.2</span
                        >
                      </vs-col>
                    </div>
                  </div>
                </template>
              </vx-card>
            </vs-col>
            <vs-col vs-w="1.3" class="mx-2">
              <vx-card class="cardAdmin overflow-hidden">
                <template slot="no-body">
                  <div class="item-details" @click="DRE()">
                    <div class="my-1">
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span>
                          <vs-icon
                            icon="history"
                            class="my-1 img"
                            size="40px"
                            color="#083357"
                          ></vs-icon>
                        </span>
                      </vs-col>
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span style="font-size: 14px" class="mb-2"
                          >Versão 4.0.1</span
                        >
                      </vs-col>
                    </div>
                  </div>
                </template>
              </vx-card>
            </vs-col>
            <vs-col vs-w="1.3" class="mx-2">
              <vx-card class="cardAdmin overflow-hidden">
                <template slot="no-body">
                  <div class="item-details" @click="DRE()">
                    <div class="my-1">
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span>
                          <vs-icon
                            icon="history"
                            class="my-1 img"
                            size="40px"
                            color="#083357"
                          ></vs-icon>
                        </span>
                      </vs-col>
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span style="font-size: 14px" class="mb-2"
                          >Versão 4.0.0</span
                        >
                      </vs-col>
                    </div>
                  </div>
                </template>
              </vx-card>
            </vs-col>
          </vs-row>
        </div> 
      </vs-col>
      <!-- Final Releases -->

      <vs-col v-if="$atividades.permissoes.includes(13)" vs-w="12" class>
        <h5 class="mt-10 mb-4">DASHBOARDS</h5>
        <div>
          <vs-row class="mt-3" vs-justify="flex-start" vs-align="flex-start">
            <vs-col vs-w="1.3" class="mx-2">
              <vx-card class="cardAdmin overflow-hidden">
                <template slot="no-body">
                  <div class="item-details" @click="DRE()">
                    <div class="my-1">
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span>
                          <vs-icon
                            icon="attach_money"
                            class="my-1 img"
                            size="40px"
                            color="#083357"
                          ></vs-icon>
                        </span>
                      </vs-col>
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span style="font-size: 14px" class="mb-2"
                          >FINANCEIRO</span
                        >
                      </vs-col>
                    </div>
                  </div>
                </template>
              </vx-card>
            </vs-col>
            <vs-col vs-w="1.3" class="mx-2">
              <vx-card class="cardAdmin overflow-hidden">
                <template slot="no-body">
                  <div class="item-details px-1" @click="RELATORIODEVENDAS()">
                    <div class="my-1">
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span>
                          <vs-icon
                            icon="bar_chart"
                            class="my-1 img"
                            size="40px"
                            color="#083357"
                          ></vs-icon>
                        </span>
                      </vs-col>
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span style="font-size: 14px" class="mb-2">VENDAS</span>
                      </vs-col>
                    </div>
                  </div>
                </template>
              </vx-card>
            </vs-col>
            <vs-col vs-w="1.3" class="mx-2">
              <vx-card class="cardAdmin overflow-hidden">
                <template slot="no-body">
                  <div class="item-details px-1" @click="DASHBOARDPCO()">
                    <div class="my-1">
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span>
                          <vs-icon
                            icon="pie_chart"
                            class="my-1 img"
                            size="40px"
                            color="#083357"
                          ></vs-icon>
                        </span>
                      </vs-col>
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span style="font-size: 14px" class="mb-2"
                          >SERVICO</span
                        >
                      </vs-col>
                    </div>
                  </div>
                </template>
              </vx-card>
            </vs-col>
          </vs-row>
        </div>
      </vs-col>

      <vs-col v-if="$atividades.permissoes.includes(1)" vs-w="12" class>
        <h5 class="mt-10 mb-4">COMERCIAL</h5>
        <div>
          <vs-row class="mt-3" vs-justify="flex-start" vs-align="flex-start">
            <vs-col vs-w="1.3" class="mx-2">
              <vx-card class="cardAdmin overflow-hidden">
                <template slot="no-body">
                  <div class="item-details" @click="GoTo('kanban')">
                    <div class="my-1">
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span>
                          <vs-icon
                            icon="table_chart"
                            class="my-1 img"
                            size="40px"
                            color="#083357"
                          ></vs-icon>
                        </span>
                      </vs-col>
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span style="font-size: 14px" class="mb-2">KANBAN</span>
                      </vs-col>
                    </div>
                  </div>
                </template>
              </vx-card>
            </vs-col>
            <vs-col vs-w="1.3" class="mx-2">
              <vx-card class="cardAdmin overflow-hidden">
                <template slot="no-body">
                  <div class="item-details px-1" @click="GoTo('agenda-lev')">
                    <div class="my-1">
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span>
                          <vs-icon
                            icon="verified_user"
                            class="my-1 img"
                            size="40px"
                            color="#083357"
                          ></vs-icon>
                        </span>
                      </vs-col>
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span style="font-size: 14px" class="mb-2"
                          >LEVANTAMENTOS</span
                        >
                      </vs-col>
                    </div>
                  </div>
                </template>
              </vx-card>
            </vs-col>
            <vs-col vs-w="1.3" class="mx-2">
              <vx-card class="cardAdmin overflow-hidden">
                <template slot="no-body">
                  <div class="item-details px-1" @click="GoTo('documentos')">
                    <div class="my-1">
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span>
                          <vs-icon
                            icon="tab"
                            class="my-1 img"
                            size="40px"
                            color="#083357"
                          ></vs-icon>
                        </span>
                      </vs-col>
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span style="font-size: 14px" class="mb-2"
                          >DOCUMENTOS</span
                        >
                      </vs-col>
                    </div>
                  </div>
                </template>
              </vx-card>
            </vs-col>
            <vs-col vs-w="1.3" class="mx-2">
              <vx-card class="cardAdmin overflow-hidden">
                <template slot="no-body">
                  <div class="item-details px-1" @click="GoTo('cliente')">
                    <div class="my-1">
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span>
                          <vs-icon
                            icon="assignment_ind"
                            class="my-1 img"
                            size="40px"
                            color="#083357"
                          ></vs-icon>
                        </span>
                      </vs-col>
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span style="font-size: 14px" class="mb-2"
                          >CLIENTES</span
                        >
                      </vs-col>
                    </div>
                  </div>
                </template>
              </vx-card>
            </vs-col>
          </vs-row>
        </div>
      </vs-col>

      <vs-col v-if="$atividades.permissoes.includes(7)" vs-w="12" class>
        <h5 class="mt-10 mb-4">PCO</h5>
        <div>
          <vs-row class="mt-3" vs-justify="flex-start" vs-align="flex-start">
            <vs-col vs-w="1.3" class="mx-2">
              <vx-card class="cardAdmin overflow-hidden">
                <template slot="no-body">
                  <div class="item-details" @click="GoTo('retorno-os')">
                    <div class="my-1">
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span>
                          <vs-icon
                            icon="keyboard_return"
                            class="my-1 img"
                            size="40px"
                            color="#083357"
                          ></vs-icon>
                        </span>
                      </vs-col>
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span style="font-size: 14px" class="mb-2"
                          >RETORNOS</span
                        >
                      </vs-col>
                    </div>
                  </div>
                </template>
              </vx-card>
            </vs-col>
            <vs-col vs-w="1.3" class="mx-2">
              <vx-card class="cardAdmin overflow-hidden">
                <template slot="no-body">
                  <div
                    class="item-details px-1"
                    @click="GoTo('os-confirmacao')"
                  >
                    <div class="my-1">
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span>
                          <vs-icon
                            icon="check"
                            class="my-1 img"
                            size="40px"
                            color="#083357"
                          ></vs-icon>
                        </span>
                      </vs-col>
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span style="font-size: 14px" class="mb-2"
                          >CONFIRMACAO</span
                        >
                      </vs-col>
                    </div>
                  </div>
                </template>
              </vx-card>
            </vs-col>
            <vs-col vs-w="1.3" class="mx-2">
              <vx-card class="cardAdmin overflow-hidden">
                <template slot="no-body">
                  <div class="item-details px-1" @click="GoTo('os-associar')">
                    <div class="my-1">
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span>
                          <vs-icon
                            icon="sort_by_alpha"
                            class="my-1 img"
                            size="40px"
                            color="#083357"
                          ></vs-icon>
                        </span>
                      </vs-col>
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span style="font-size: 14px" class="mb-2"
                          >ASSOCIAR</span
                        >
                      </vs-col>
                    </div>
                  </div>
                </template>
              </vx-card>
            </vs-col>
            <vs-col vs-w="1.3" class="mx-2">
              <vx-card class="cardAdmin overflow-hidden">
                <template slot="no-body">
                  <div class="item-details px-1" @click="GoTo('pos-venda')">
                    <div class="my-1">
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span>
                          <vs-icon
                            icon="phone_forwarded"
                            class="my-1 img"
                            size="40px"
                            color="#083357"
                          ></vs-icon>
                        </span>
                      </vs-col>
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span style="font-size: 14px" class="mb-2"
                          >POS VENDA</span
                        >
                      </vs-col>
                    </div>
                  </div>
                </template>
              </vx-card>
            </vs-col>
            <vs-col vs-w="1.3" class="mx-2">
              <vx-card class="cardAdmin overflow-hidden">
                <template slot="no-body">
                  <div class="item-details px-1" @click="GoTo('agenda-os')">
                    <div class="my-1">
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span>
                          <vs-icon
                            icon="calendar_today"
                            class="my-1 img"
                            size="40px"
                            color="#083357"
                          ></vs-icon>
                        </span>
                      </vs-col>
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span style="font-size: 14px" class="mb-2">AGENDA</span>
                      </vs-col>
                    </div>
                  </div>
                </template>
              </vx-card>
            </vs-col>
          </vs-row>
        </div>
      </vs-col>

      <vs-col v-if="$atividades.permissoes.includes(4)" vs-w="12" class>
        <h5 class="mt-10 mb-4">FINANCEIRO</h5>
        <div>
          <vs-row class="mt-3" vs-justify="flex-start" vs-align="flex-start">
            <vs-col vs-w="1.3" class="mx-2">
              <vx-card class="cardAdmin overflow-hidden">
                <template slot="no-body">
                  <div class="item-details" @click="GoTo('contas-receber')">
                    <div class="my-1">
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span>
                          <vs-icon
                            icon="attach_money"
                            class="my-1 img"
                            size="40px"
                            color="#083357"
                          ></vs-icon>
                        </span>
                      </vs-col>
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span style="font-size: 14px" class="mb-2"
                          >A RECEBER</span
                        >
                      </vs-col>
                    </div>
                  </div>
                </template>
              </vx-card>
            </vs-col>
            <vs-col vs-w="1.3" class="mx-2">
              <vx-card class="cardAdmin overflow-hidden">
                <template slot="no-body">
                  <div class="item-details px-1" @click="GoTo('notas-entrada')">
                    <div class="my-1">
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span>
                          <vs-icon
                            icon="money_off"
                            class="my-1 img"
                            size="40px"
                            color="#083357"
                          ></vs-icon>
                        </span>
                      </vs-col>
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span style="font-size: 14px" class="mb-2"
                          >A PAGAR</span
                        >
                      </vs-col>
                    </div>
                  </div>
                </template>
              </vx-card>
            </vs-col>
            <vs-col vs-w="1.3" class="mx-2">
              <vx-card class="cardAdmin overflow-hidden">
                <template slot="no-body">
                  <div class="item-details px-1" @click="GoTo('caixa')">
                    <div class="my-1">
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span>
                          <vs-icon
                            icon="account_balance"
                            class="my-1 img"
                            size="40px"
                            color="#083357"
                          ></vs-icon>
                        </span>
                      </vs-col>
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span style="font-size: 14px" class="mb-2">CAIXA</span>
                      </vs-col>
                    </div>
                  </div>
                </template>
              </vx-card>
            </vs-col>
            <vs-col vs-w="1.3" class="mx-2">
              <vx-card class="cardAdmin overflow-hidden">
                <template slot="no-body">
                  <div
                    class="item-details px-1"
                    @click="GoTo('contratos-faturamento')"
                  >
                    <div class="my-1">
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span>
                          <vs-icon
                            icon="payments"
                            class="my-1 img"
                            size="40px"
                            color="#083357"
                          ></vs-icon>
                        </span>
                      </vs-col>
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span style="font-size: 14px" class="mb-2"
                          >PARA FATURAR</span
                        >
                      </vs-col>
                    </div>
                  </div>
                </template>
              </vx-card>
            </vs-col>
            <vs-col vs-w="1.3" class="mx-2">
              <vx-card class="cardAdmin overflow-hidden">
                <template slot="no-body">
                  <div class="item-details px-1" @click="GoTo('cobranca')">
                    <div class="my-1">
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span>
                          <vs-icon
                            icon="phone_enabled"
                            class="my-1 img"
                            size="40px"
                            color="#083357"
                          ></vs-icon>
                        </span>
                      </vs-col>
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span style="font-size: 14px" class="mb-2"
                          >COBRANCA</span
                        >
                      </vs-col>
                    </div>
                  </div>
                </template>
              </vx-card>
            </vs-col>
            <vs-col vs-w="1.3" class="mx-2">
              <vx-card class="cardAdmin overflow-hidden">
                <template slot="no-body">
                  <div class="item-details px-1" @click="GoTo('nota')">
                    <div class="my-1">
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span>
                          <vs-icon
                            icon="receipt_long"
                            class="my-1 img"
                            size="40px"
                            color="#083357"
                          ></vs-icon>
                        </span>
                      </vs-col>
                      <vs-col
                        vs-w="12"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <span style="font-size: 14px" class="mb-2">NOTAS</span>
                      </vs-col>
                    </div>
                  </div>
                </template>
              </vx-card>
            </vs-col>
          </vs-row>
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import lembreteModal from "./pages/kanban/components/lembreteModal.vue";

export default {
  data() {
    return {
      nome: window.localStorage.getItem("nome")
    };
  },
  methods: {
    async Caixa() {
      this.$router.push("/caixa");
    },
    async Boleto() {
      this.$router.push("/boleto");
    },
    async ContasReceber() {
      this.$router.push("/contas-receber");
    },
    async NotasEntrada() {
      this.$router.push("/notas-entrada");
    },
    async CLIENTES() {
      this.$router.push("/cliente");
    },
    async DOCUMENTOS() {
      this.$router.push("/documentos");
    },
    async KANBAN() {
      this.$router.push("/kanban");
    },
    async PARAFATURAR() {
      this.$router.push("/contratos-faturamento");
    },
    async ASSOCOS() {
      this.$router.push("/os-associar");
    },
    async CONFIRMOS() {
      this.$router.push("/os-confirmacao");
    },
    async RELATORIODEVENDAS() {
      this.$router.push("/relatorio-vendas");
    },
    async DASHBOARDPCO() {
      this.$router.push("/relatorio-pco");
    },
    async DRE() {
      this.$router.push("/dre");
    },
    async GoTo(link) {
      this.$router.push(`/${link}`);
    }
  },
  components: {
    VueApexCharts,
    VuePerfectScrollbar,
    lembreteModal
  }
};
</script>
<style lang="scss" scoped>
.cardHome {
  transition-duration: 50ms;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);

  &:hover {
    background-color: #1759a0;
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    color: white;
    cursor: pointer;
    .img {
      color: white !important;
    }

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.cardAdmin {
  transition-duration: 50ms;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);

  &:hover {
    background-color: #083357;
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    color: white;
    cursor: pointer;
    .img {
      color: white !important;
    }

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.sizeScrollContratoTab {
  height: 72vh !important;
}
</style>
